@mixin s-sparkle($color) {
    width: 16px;
    height: 16px;
    background-image: inline-svg(
        "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 39.86'><g ><path d='M0 19.93C16.86 18.25 18.26 16.85 20 0c1.74 16.85 3.14 18.25 20 19.93C23.14 21.61 21.74 23 20 39.86 18.26 23 16.86 21.61 0 19.93z' fill='#{$color}'/></g></svg>"
    );
    background-size: contain;
    background-position: center !important;
}

.s-sparkle-red {
    @include s-sparkle($red);
}

.s-sparkle-white {
    @include s-sparkle($white);
}
