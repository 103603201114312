/**
 * Platform base spacing unit
 */
$base-spacing-unit: 20px;

/**
 * Variations
 */
$quadruple-base-spacing-unit: round($base-spacing-unit * 4);
$triple-base-spacing-unit: round($base-spacing-unit * 3);
$double-base-spacing-unit: round($base-spacing-unit * 2);
$half-base-spacing-unit: round($base-spacing-unit / 2);
$quarter-base-spacing-unit: round($base-spacing-unit / 4);

/**
 * Colors variables
 */

/* Black */
$black: #000;

/* Blue */
$blue-darker: #2c3758;
$blue-dark: #4fb1c7;
$blue: #64a0c8;
$blue-mid-light: #c2dae9;
$blue-light: #e8f1f7;

/* Brown */
$brown: #4d3928;

/* Green */
$green: #3E734C;
$green-light: #B6D3BB;
$green-extra-light: #e7ece7;

/* Grey */
$grey-dark: #313131;
$grey-mid-dark: $brown;
$grey: #a59d95;
$grey-mid-light: #cec5bc;
$grey-light: #eeeeee;
$grey-extra-light: #fafaf7;

/* Orange */
$orange: #ab5f36;
$orange-light: #f8bb99;
$orange-extra-light: #fee9de;

/* Red */
$red-dark: #983222;
$red: #aa182c;
$red-extra-light: #f2e3e3;

/* Taupe */
$taupe-dark: #c1bab4;
$taupe: #d3bf96;
$taupe-light: #ede5d5;
$taupe-extra-light: #f6f2ea;

/* Transparent */
$transparent: transparent;

/* Yellow */
$yellow-dark: #d1900b;
$yellow-taupe: #be955a;
$yellow: #eeaf30;
$yellow-light: #f7d797;

/* White */
$white: #fff;

// Redesign

$color-eft-linen-light: #fcf5ed;


/**
 * Base color usage
 */
$default-border-color: $taupe-light;

/**
 * Base platform properties
 */
$base-radius: 5px;

/**
 * Fonts
 */
$ui-face: 'Efteling', serif;
$brand-face: 'Alegreya', sans-serif;
$brand-face-bold: 'Alegreya', sans-serif;
$base-face: 'Alegreya Sans', sans-serif;

/**
 * Copy text styling
 */
$copyFactor: 1.5;

$copy-default: 18px;
$copy-default-height: $copyFactor;

$copy-medium: 14px;
$copy-medium-height: $copyFactor;

$copy-small: 12px;
$copy-small-height: $copyFactor;

/**
 * Variables to include as .bg--{key}
 *
 * Example:
 * dark: #000000
 *
 * will be compiled to
 * .bg--dark { background-color: #000000; }
 *
 * Used in /partials/_colors.scss
 */
$bg-colors: (
    'black': $black,
    'blue-darker': $blue-darker,
    'blue': $blue,
    'blue-light': $blue-light,
    'brown': $brown,
    'green': $green,
    'green-light': $green-light,
    'grey-dark': $grey-dark,
    'grey': $grey,
    'grey-light': $grey-light,
    'grey-extra-light': $grey-extra-light !important,
    'eft-linen-light': $color-eft-linen-light,
    'orange': lighten($orange, 25%),
    'orange-light': $orange-light,
    'orange-extra-light': $orange-extra-light,
    'red': $red,
    'red-extra-light': rgba($red, 0.1),
    'taupe-dark': $taupe-dark,
    'taupe': $taupe,
    'taupe-light': $taupe-light,
    'taupe-extra-light': $taupe-extra-light,
    'transparent': $transparent !important,
    'yellow': $yellow,
    'yellow-light': $yellow-light,
    'white': $white
);

/**
 * Variables to include as .text--{key}
 *
 * Example:
 * 'negative': #ff0000
 *
 * will be compiled to
 * .text--negative { color: #ff0000; }
 *
 * Used in /partials/_colors.scss
 */
$text-colors: (
    'black': $black,
    'blue-dark': $blue-dark,
    'blue-darker': $blue-darker,
    'blue': $blue,
    'brown': $brown,
    'green': $green,
    'green-light': $green-light,
    'grey-dark': $grey-dark,
    'grey-mid-dark': $grey-mid-dark,
    'grey': $grey,
    'grey-light': $grey-light,
    'grey-extra-light': $grey-extra-light,
    'orange': $orange,
    'orange-light': $orange-light,
    'red': $red,
    'taupe-dark': $taupe-dark,
    'taupe': $taupe,
    'taupe-light': $taupe-light,
    'taupe-extra-light': $taupe-extra-light,
    'yellow': $yellow,
    'yellow-taupe': $yellow-taupe,
    'yellow-light': $yellow-light,
    'yellow-dark': $yellow-dark,
    'white': $white
);

/**
 * Variables to include as .border--{key}
 *
 * Example:
 * 'negative': #ff0000
 *
 * will be compiled to
 * .border--negative { color: #ff0000; }
 *
 * Used in /partials/_colors.scss
 */
$border-colors: (
    'blue': $blue,
    'blue-mid-light': $blue-mid-light,
    'brown': $brown,
    'green': lighten($green, 10%),
    'grey': $grey,
    'grey-mid-light': $grey-mid-light,
    'grey-light': $grey-light,
    'orange': lighten($orange, 10%),
    'orange-light': $orange-light,
    'red': lighten($red, 10%),
    'taupe': $taupe,
    'taupe-light': $taupe-light,
    'taupe-extra-light': $taupe-extra-light,
    'white': $white
);

$social-colors: (
    'facebook': #3b5999,
    'twitter': #55acee,
    'instagram': #e4405f,
    'youtube': #cd201f,
    'musically': #ffffff,
    'linkedin': #0077b5
);

/**
 * Variables to include as .{key}
 *
 * Example:
 * 'container': 980px
 *
 * will be compiled to
 * .container { width: 980px; margin: 0 auto; }
 *
 * Used in /partials/_containers.scss
 */
$containers: (
    'container--l': 1024px,
    'container--fancybox': 680px,
    'container--m': 504px
);

/**
 * Icon sizes
 */
$icon-width: 25px;
$icon-height: 25px;

/**
 * Input specs
 */
$input-spacing: 10px;
$input-border-color: #d2ceca;

/**
 * Headings sizes
 * [1] - Used for .giga
 * [2] - Used for desk, lap & palm h1
 * [3] - Used for desk & lap h2
 * [4] - Used for desk & lap h3 AND palm h2, h3, h4
 * [5] - Used for desk, lap h4 AND palm h5
 * [6] - Used for desk, lap h5
 * [7] - Used for palm .palm-zeta
 * [8] - Used for palm .palm-milli
 * [9] - Used for .micro
 * [10] - Used for .nano
 *
 * CHEATSHEET
 * Desk & Lap:
 * h1, .giga - 60px
 * .alpha - 48px
 * h2, .beta - 36px
 * h3, .gamma - 24px
 * h4, .delta - 20px
 * h5, .epsilon - 16px
 * h6, .zeta - 14px
 * .milli - 12px
 *
 * Palm:
 * h1, .giga - 36px
 * .alpha - 48px
 * h2, .beta - 24px
 * h3, .gamma - 24px
 * h4, .delta - 24px
 * h5, .epsilon - 20px
 * h6, .epsilon - 14px
 * .milli - 12px
 */
$giga-size: 60px; /* [1] */
$alpha-size: 48px; /* [2] */
$beta-size: 32px; /* [3] */
$gamma-size: 24px; /* [4] */
$delta-size: 24px; /* [5] */
$epsilon-size: 21px; /* [6] */
$zeta-size: 18px; /* [7] */
$milli-size: 14px; /* [8] */
$micro-size: 14px; /* [9] */
$nano-size: 14px; /* [10] */

$animationSpeed: 0.15s;
