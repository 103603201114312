/**
 * Settings for colors
 */
@import 'settings/vars';
@import 'sprite/animated-icons';
@import 'tools/inline-svg';

// Animated sparkles at the end of the fancy rulers
@keyframes sparkle {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(0.5) rotate(180deg);
    }

    100% {
        transform: scale(1) rotate(360deg);
    }
}

// Animate the widht of the fancy rulers
@keyframes fancy-rule {
    0% {
        width: 0;
    }

    100% {
        width: 50%;
    }
}

@keyframes fancy-rule100 {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

// fade in animation
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// fade in animation
@keyframes fadeInDelay {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// fade in animation
@keyframes blinkEyes {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    20% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    40% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

/**
 * Animated Fancy Rules
 */
.fancy-rule--animated {
    .animated-s-apple--container::before,
    .animated-s-egg-cracked--container::before,
    .animated-s-goat-head--container::before {
        content: '';
        background: currentColor;
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        border-radius: 100px;
        top: 6px;
        left: 2px;
        animation: fadeIn 300ms forwards;
        animation-delay: 6s;
        animation-direction: reverse;
    }

    .animated-s-egg-cracked--container::before {
        width: 10px;
        height: 20px;
        top: 2px;
        left: 8px;
    }

    .animated-s-goat-head--container::before {
        top: 10px;
        left: 10px;
        width: 10px;
        height: 5px;
        animation: blinkEyes 6s infinite;
    }

    .animated-s-nightsky--container::before,
    .animated-s-nightsky--container::after {
        position: absolute;
        content: '';
        opacity: 0;
        display: block;
        background-color: transparent;
        background-size: cover;
        background-position: center !important;
        animation: fadeIn 2s forwards;
    }

    .animated-s-nightsky--container::before {
        right: 0;
        top: 8px;
        width: 10px;
        height: 10px;
        background-image: inline-svg(
            "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.04 12.17'><g><path d='M12.04 3.2l-4.54.77L5.17 0 4.5 4.55 0 5.54l4.13 2.05-.45 4.58L6.9 8.88l4.22 1.84-2.14-4.08 3.06-3.44z' fill='#{$red}'/></g></svg>"
        );
        animation-delay: 6s;
    }

    &.fancy-rule--white .animated-s-nightsky--container::before {
        background-image: inline-svg(
            "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.04 12.17'><g><path d='M12.04 3.2l-4.54.77L5.17 0 4.5 4.55 0 5.54l4.13 2.05-.45 4.58L6.9 8.88l4.22 1.84-2.14-4.08 3.06-3.44z' fill='#{$white}'/></g></svg>"
        );
    }

    .animated-s-nightsky--container::after {
        right: 7px;
        top: 0;
        width: 11px;
        height: 10px;
        background-image: inline-svg(
            "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.95 13.61'><path d='M9.33 5.44l1.18 3.17 1.06-3.21 3.38-.14-2.72-2L13.14 0l-2.75 1.97L7.58.1 8.6 3.32l-2.65 2.1 3.38.02z' fill='#{$red}'/><path d='M1.26 13.61l2.06-1.42 2.05 1.42-.72-2.39L6.64 9.7l-2.5-.06-.82-2.35-.83 2.35L0 9.7l1.98 1.52-.72 2.39z' fill='#{$red}'/></svg>"
        );
        animation: fadeIn 2s forwards;
        animation-delay: 7s;
    }

    &.fancy-rule--white .animated-s-nightsky--container::after {
        background-image: inline-svg(
            "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.95 13.61'><path d='M9.33 5.44l1.18 3.17 1.06-3.21 3.38-.14-2.72-2L13.14 0l-2.75 1.97L7.58.1 8.6 3.32l-2.65 2.1 3.38.02z' fill='#{$white}'/><path d='M1.26 13.61l2.06-1.42 2.05 1.42-.72-2.39L6.64 9.7l-2.5-.06-.82-2.35-.83 2.35L0 9.7l1.98 1.52-.72 2.39z' fill='#{$white}'/></svg>"
        );
    }

    .animated-s-battery--container::before,
    .animated-s-battery--container::after {
        content: '';
        background: currentColor;
        width: 4px;
        height: 9px;
        display: block;
        position: absolute;
        border-radius: 1px;
        top: 11px;
        left: 13px;
        transform: rotate(13deg);
        animation: fadeIn 2500ms infinite;
        animation-timing-function: cubic-bezier(0, 0, 0, 1);
    }

    .animated-s-battery--container::after {
        left: 19px;
        top: 12px;
        animation: fadeInDelay 2500ms infinite;
    }

    .animated-s-butterfly--container {
        transform: translateZ(-100px); // Fix for Safari bug EFTB-341
    }

    .animated-s-butterfly {
        transform: rotateY(0deg);
        animation: butterfly 4s infinite;
        transform-style: preserve-3d;
    }

    @keyframes butterfly {
        0% {
            transform: rotateY(0deg);
        }

        30% {
            transform: rotateY(-50deg);
        }

        50% {
            transform: rotateY(-20deg);
        }

        70% {
            transform: rotateY(-50deg);
        }

        100% {
            transform: rotateY(0deg);
        }
    }

    .animated-s-diamond--container {
        &::before,
        &::after {
            width: 0.5em;
            height: 0.5em;
            content: '';
            display: block;
            position: absolute;
            top: -0.5em;
            left: 0;
            animation: sparkle 4s infinite;
        }

        &::after {
            top: 0.5em;
            left: auto;
            right: -0.5em;
            animation-delay: 1s;
        }
    }

    &.fancy-rule--red .animated-s-diamond--container {
        &::before,
        &::after {
            @include s-sparkle($red);
        }
    }

    &.fancy-rule--white .animated-s-diamond--container {
        &::before,
        &::after {
            @include s-sparkle($white);
        }
    }

    .animated-s-fairy {
        animation: fairy 8s infinite;
        transition: transform;
    }

    @keyframes fairy {
        0% {
            transform: translate(-5px, -5px);
        }

        20% {
            transform: translate(-5px, 0);
        }

        40% {
            transform: translate(0, 0);
        }

        60% {
            transform: translate(5px, -10px);
        }

        100% {
            transform: translate(-5px, -5px);
        }
    }

    .animated-s-ferris-wheel--container {
        &::before,
        &::after {
            content: '';
            position: absolute;
            bottom: -0.4em;
            left: 50%;
            width: 0.15em;
            height: 1.4em;
            background: currentColor;
            transform: rotate(24deg) translateX(-0.4em);
        }

        &::after {
            transform: rotate(-24deg) translateX(0.4em);
            left: auto;
            right: 50%;
        }
    }

    .animated-s-ferris-wheel {
        animation: ferrisWheel 12s infinite linear;
    }

    @keyframes ferrisWheel {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .animated-s-frog-king--container {
        &::after {
            content: '';
            border: 0.1em dashed transparent;
            border-top-color: currentColor;
            display: block;
            position: absolute;
            width: 5em;
            height: 5em;
            left: 50%;
            top: -0.5em;
            border-radius: 50%;
            animation: frogKing 4s linear infinite;
        }
    }

    @keyframes frogKing {
        0% {
            opacity: 0;
            transform: rotateZ(-50deg);
        }

        15% {
            opacity: 1;
        }

        30% {
            opacity: 0;
            transform: rotateZ(30deg);
        }

        100% {
            opacity: 0;
            transform: rotateZ(-50deg);
        }
    }

    .animated-s-halve-maen {
        transform: rotate(-100deg);
        animation: halvemaen 8s infinite;
    }

    @keyframes halvemaen {
        0% {
            transform: rotate(-100deg);
        }

        50% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(-100deg);
        }
    }

    .animated-s-mine-cart {
        animation: mine-cart 2s infinite;
        transition: transform;
    }

    @keyframes mine-cart {
        0% {
            transform: translate(0, 0);
        }

        10% {
            transform: translate(0, 1px);
        }

        20% {
            transform: translate(0, -1px);
        }

        30% {
            transform: translate(0, 1px);
        }

        40% {
            transform: translate(0, 0) rotate(-10deg);
        }

        50% {
            transform: translate(0, -6px) rotate(0deg);
        }

        60% {
            transform: translate(0, 0) rotate(10deg);
        }

        70% {
            transform: translate(0, 1px);
        }

        80% {
            transform: translate(0, 0);
        }

        90% {
            transform: translate(0, -1px);
        }

        100% {
            transform: translate(0, 0);
        }
    }

    .animated-s-pagode--container {
        display: inline-block;
        overflow: hidden;
    }

    .animated-s-pagode {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-4px, 0);
        animation: pagode 8s infinite;
    }

    @keyframes pagode {
        0% {
            transform: translate(-4px, 0);
        }

        50% {
            transform: translate(4px, 11px);
        }

        100% {
            transform: translate(-4px, 0);
        }
    }

    .animated-s-parking {
        animation: ridingCar 8s infinite;
        will-change: transform;
    }

    @keyframes ridingCar {
        0% {
            transform: translate(0, 0);
        }

        20% {
            transform: translate(4px, 0);
        }

        40% {
            transform: translate(0, 0);
        }

        50% {
            transform: translate(-4px, 0);
        }

        60% {
            transform: translate(0, 0);
        }

        80% {
            transform: translate(4px, 0);
        }

        100% {
            transform: translate(0, 0);
        }
    }

    .animated-s-pirana-boat {
        animation: pirana 8s infinite;
        will-change: transform;
    }

    @keyframes pirana {
        0% {
            transform: rotate(0);
        }

        30% {
            transform: rotate(10deg);
        }

        50% {
            transform: rotate(0deg);
        }

        80% {
            transform: rotate(15deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    .animated-s-raven-flight {
        animation: flyingRaven 4s infinite;
        will-change: transform;
    }

    @keyframes flyingRaven {
        0% {
            transform: skew(0, 0);
        }

        40% {
            transform: skew(-20deg, -20deg);
        }

        60% {
            transform: skew(-10deg, -10deg);
        }

        80% {
            transform: skew(-20deg, -20deg);
        }

        100% {
            transform: skew(0, 0);
        }
    }

    .animated-s-rudder {
        animation: rudder 8s infinite;
        transition: transform;
        will-change: transform;
    }

    @keyframes rudder {
        0% {
            transform: rotate(180deg);
        }

        60% {
            transform: rotate(-180deg);
        }

        100% {
            transform: rotate(180deg);
        }
    }

    .animated-s-shield {
        animation: shield 4s infinite;
        transition: transform;
    }

    @keyframes shield {
        0% {
            transform: scale3d(-1, 1, 1);
        }

        30% {
            transform: scale3d(1, 1, 1);
        }

        60% {
            transform: scale3d(-1, 1, 1);
        }

        70% {
            transform: scale3d(-1.2, 1.2, 1);
        }

        75% {
            transform: scale3d(-1, 1, 1);
        }

        80% {
            transform: scale3d(-1.2, 1.2, 1);
        }

        100% {
            transform: scale3d(-1, 1, 1);
        }
    }

    .animated-s-jokie {
        transform: rotate(-20deg);
        animation: jokie 4s infinite;
        will-change: transform;
    }

    @keyframes jokie {
        0% {
            transform: rotate(-20deg);
        }

        50% {
            transform: rotate(20deg);
        }

        100% {
            transform: rotate(-20deg);
        }
    }

    .animated-s-max-moritz {
        animation: maxMoritz 4s infinite;
        will-change: transform;
    }

    @keyframes maxMoritz {
        0% {
            transform: scale(0.5);
            opacity: 0;
        }

        20% {
            opacity: 1;
        }

        40% {
            transform: scale(1);
        }

        45% {
            transform: scale(0.8);
        }

        50% {
            transform: scale(1);
        }

        55% {
            transform: scale(0.8);
        }

        60% {
            transform: scale(1);
        }

        80% {
            opacity: 1;
        }

        100% {
            transform: scale(0.5);
            opacity: 0;
        }
    }
}
